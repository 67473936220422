<template>
  <div class="relative bg-white">
    <router-link
      :to="{ name: 'home' }"
      class="absolute grid w-12 h-12 bg-white rounded-full shadow-lg top-4 left-4 z-999 place-items-center"
    >
      <icon-home class="w-6" role="button" />
    </router-link>

    <tutorial />

    <transition
      name="slide-fade"
      :enter-class="enterClass"
      :leave-to-class="leaveToClass"
      mode="out-in"
    >
      <component
        :is="activeComponent"
        @onNextClick="onNextButtonClick"
        @onPreviousClick="onPreviousButtonClick"
      ></component>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TutorialOne from "../../components/Tutorial/TutorialOne";
import TutorialTwo from "../../components/Tutorial/TutorialTwo";
import TutorialThree from "../../components/Tutorial/TutorialThree";
import TutorialFive from "../../components/Tutorial/TutorialFive";
import TutorialSix from "../../components/Tutorial/TutorialSix";
import TutorialSeven from "../../components/Tutorial/TutorialSeven";
import TutorialEight from "../../components/Tutorial/TutorialEight";
import TutorialNine from "../../components/Tutorial/TutorialNine";
import TutorialTen from "../../components/Tutorial/TutorialTen";
import TutorialEleven from "../../components/Tutorial/TutorialEleven";
import IconHome from "@/assets/icons/home.svg";
import Tutorial from "../../components/Modals/Tutorial.vue";
import { modalTypes } from "../../utils/constants";

export default {
  name: "TutorialIndex",

  components: {
    TutorialOne,
    TutorialTwo,
    TutorialThree,
    TutorialFive,
    TutorialSix,
    TutorialSeven,
    TutorialEight,
    TutorialNine,
    TutorialTen,
    TutorialEleven,
    IconHome,
    Tutorial,
  },

  data() {
    return {
      activeComponent: "TutorialOne",
      leaveToClass: "leave-to-next",
      enterClass: "enter-next",
    };
  },

  mounted() {
    if (!this.getProfile.modal_preferences.show_tutorial_modal) return;

    this.$store.commit("TOGGLE_MODAL", {
      modalType: modalTypes.TUTORIAL_MODAL,
      modalState: true,
    });
  },

  methods: {
    onNextButtonClick(component) {
      this.leaveToClass = "leave-to-next";
      this.enterClass = "enter-next";
      this.activeComponent = component;
    },

    onPreviousButtonClick(component) {
      this.leaveToClass = "leave-to-previous";
      this.enterClass = "enter-previous";
      this.activeComponent = component;
    },
  },

  computed: {
    ...mapGetters(["getProfile"]),
  },
};
</script>

<style scoped>
.leave-to-next {
  opacity: 1;
  transform: translateX(-0.25rem);
}

.leave-to-previous {
  opacity: 1;
  transform: translateX(0.25rem);
}

.enter-next {
  opacity: 0;
  transform: translateX(0.25rem);
}

.enter-previous {
  opacity: 0;
  transform: translateX(-0.25rem);
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(0.29, 0.17, 0.63, 0.9);
}

.slide-fade-enter {
  transform: translateX(1rem);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-1rem);
  opacity: 0;
}
</style>
