<template>
  <template-tutorial @swipe-left="$emit('onPreviousClick', 'TutorialTen')">
    <div class="w-full self-center rounded-t-xl bg-white p-6 pb-16 md:max-w-md">
      <div class="mb-8">
        <h1 class="mb-4 text-center text-2xl font-bold">
          {{ $t("tutorial.eleven.title") }}
        </h1>

        <div
          class="prose max-h-80 max-w-none overflow-auto break-words text-black"
        >
          <vue-markdown>{{ $t("tutorial.eleven.description") }}</vue-markdown>
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <button
            @click="$emit('onPreviousClick', 'TutorialTen')"
            class="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-brand-dark shadow-md"
          >
            <icon-left class="h-4 w-4" />
          </button>

          <div class="flex items-center justify-between gap-4">
            <button
              class="h-2 w-2 rounded-full bg-brand-dark"
              @click="$emit('onPreviousClick', 'TutorialTen')"
            ></button>
            <button class="h-2 w-2 rounded-full bg-brand-primary"></button>
            <button class="h-2 w-2 rounded-full bg-white"></button>
          </div>

          <div>
            <router-link
              :to="{ name: 'home' }"
              class="rounded-full bg-brand-dark p-1 px-3 text-sm text-white"
              >{{ $t("action.start") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </template-tutorial>
</template>

<script>
import TemplateTutorial from "./Template.vue";
import IconLeft from "@/assets/icons/chevron-left.svg";
import VueMarkdown from "vue-markdown";

export default {
  name: "TutorialTen",

  components: {
    TemplateTutorial,
    IconLeft,
    VueMarkdown,
  },
};
</script>

<style scoped></style>
