<template>
  <modal :show="tutorialModalOpen" @close="handleClose">
    <div class="p-6">
      <h5 class="mb-4 font-semibold">{{ $t("tutorial.modal.title") }}</h5>

      <p class="mb-4">
        {{ $t("tutorial.modal.content") }}
      </p>

      <t-button @click="handleClose" variant="primary">
        {{ $t("action.ok") }}
      </t-button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { modalTypes } from "utils/constants";
import Modal from "components/Modal";

export default {
  name: "Tutorial",
  components: {
    Modal,
  },

  props: {
    event: {
      type: Object,
    },
  },

  methods: {
    handleClose() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.TUTORIAL_MODAL,
        modalState: false,
      });
    },
  },

  computed: {
    ...mapGetters(["tutorialModalOpen"]),
  },
};
</script>
