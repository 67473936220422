<template>
  <div
    class="relative flex h-screen flex-col items-center overflow-hidden bg-white"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    @click="onPageClick"
  >
    <div class="absolute inset-0 z-10" v-if="hasImage">
      <img
        @click="onImageClick"
        :src="image"
        alt="foto"
        class="h-full w-full self-end object-cover"
      />
    </div>

    <transition name="fade">
      <div v-if="showImage" class="fixed inset-0 z-30">
        <img
          :src="image"
          alt="foto"
          class="absolute top-1/2 right-1/2 z-999 w-8/12 translate-x-1/2 -translate-y-1/2 transform self-end rounded-lg object-contain"
        />
        <div
          class="absolute inset-0 bg-gray-800 bg-opacity-50"
          @click.self="onImageClick"
        ></div>
      </div>
    </transition>

    <div class="relative z-20 flex h-full w-full" data-content="true">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Template",

  props: {
    image: {
      type: String,
      required: false,
    },
  },

  data: function () {
    return {
      clientX: 0,
      clientY: 0,
      showImage: false,
    };
  },

  methods: {
    onPageClick(e) {
      if (e.target.dataset.content) {
        const y = e.clientY;
        const top = e.target.firstChild.getBoundingClientRect().top;

        if (y < top) {
          this.showImage = true;
        }
      }
    },

    onTouchStart(event) {
      if (this.showImage) return;

      const [touches] = event.touches;
      const { clientX, clientY } = touches;
      this.clientX = clientX;
      this.clientY = clientY;
    },

    onTouchEnd(event) {
      if (this.showImage) return;

      const [touches] = event.changedTouches;
      const { clientX, clientY } = touches;
      const deltaX = clientX - this.clientX;
      const deltaY = clientY - this.clientY;

      const isSwipe = Math.abs(deltaX) > Math.abs(deltaY);

      if (isSwipe) {
        if (deltaX > 50) {
          this.$emit("swipe-left");
        } else if (deltaX < -50) {
          this.$emit("swipe-right");
        }
      }
    },

    onImageClick() {
      this.showImage = !this.showImage;
    },
  },

  computed: {
    hasImage() {
      return !!this.image;
    },
  },
};
</script>

<style scoped>
@media (max-height: 670px) {
  .template__image {
    @apply row-span-3;
  }

  .template__image img {
    @apply max-h-56;
  }
}
</style>
