<template>
  <tutorial-template
    :image="image"
    @swipe-right="$emit('onNextClick', 'TutorialTwo')"
  >
    <div
      class="tutorial-content w-full self-end rounded-t-xl bg-white p-6 pb-24 md:max-w-md"
    >
      <div class="mb-8 mt-4 text-center">
        <h1 class="mb-4 text-2xl font-bold">{{ $t("tutorial.one.title") }}</h1>

        <p class="mb-2">{{ $t("tutorial.one.description") }}</p>

        <p>
          {{ $t("tutorial.one.description_extra") }}
        </p>
      </div>

      <div>
        <div class="flex items-center justify-center">
          <div class="mx-32 flex items-center justify-between gap-4">
            <button class="h-2 w-2 rounded-full bg-white"></button>
            <button class="h-2 w-2 rounded-full bg-brand-primary"></button>
            <button
              class="h-2 w-2 rounded-full bg-brand-dark"
              @click="$emit('onNextClick', 'TutorialTwo')"
            ></button>
          </div>
          <button
            @click="$emit('onNextClick', 'TutorialTwo')"
            class="flex h-8 w-8 items-center justify-center rounded-full bg-brand-dark shadow-md"
          >
            <icon-right class="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  </tutorial-template>
</template>

<script>
import TutorialTemplate from "./Template.vue";
import IconRight from "@/assets/icons/chevron-right.svg";

export default {
  name: "TutorialOne",

  components: {
    TutorialTemplate,
    IconRight,
  },

  computed: {
    image() {
      return require(`@/assets/tutorial/tutorial_one_${this.$i18n.locale}.png`);
    },
  },
};
</script>

<style scoped></style>
